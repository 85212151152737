.buttonSecondary {
  background: white;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  text-transform: var(--button-secondary-text-transform);
}

.buttonSecondary:focus {
  box-shadow: var(--form-control-focused-box-shadow);
}

.buttonSecondary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonSecondary:not(:disabled):hover {
    background-color: var(--color-primary);
    color: white;
  }

  .buttonSecondary:not(:disabled):active {
    opacity: 0.8;
  }
}
