.header {
  position: sticky;
  display: grid;
  align-items: center;
  top: 0;
  background-color: var(--header-background-color);
  border-bottom: 1px solid var(--header-border-color);
  padding: var(--sp0) var(--sp4);
  height: var(--header-height);
  z-index: var(--z-index-header);
}

.header a {
  line-height: 1;
}

.header a:hover,
.header button:hover {
  opacity: 0.9;
}

.logo {
  /* height: 38px; */
  height: 28px;
  /* Align manually, since the image has some space at the bottom */
  /* margin-bottom: -2px; */
}

.headerLayout {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp5);
}

.headerLinks {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: var(--sp5);
}

.headerLink {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--sp1);
  color: var(--header-color);
  font-size: var(--fs0);
  font-weight: 500;
}

.headerEndLayout {
  /* TODO */
  /* display: grid;
  grid-template-columns: auto auto;
  justify-self: end;
  align-items: center;
  gap: var(--sp5); */
}

.sideMenuButtonContainer button {
  display: grid;
}

.sideMenuButtonContainer svg {
  color: var(--header-color);
  font-size: var(--fs6);
}

@media (min-width: 768px) {
  .headerLayout {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    gap: var(--sp6);
  }
}

@media print {
  .header {
    display: none;
  }
}
