.root {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: var(--sp6) var(--pg-margin-horizontal);
}

.inner {
  margin: 0 auto;
  max-width: 500px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: var(--sp5) var(--sp7);
  border-radius: var(--br2);
  background-color: var(--color-danger-000);
  border: 1px solid var(--color-danger-100);
}

.text {
  font-size: var(--fs4);
  font-weight: 500;
  color: var(--color-danger-500);
}
