:root {
  --avatar_xs_size: 26px;
  --avatar_sm_size: 32px;
  --avatar_size: 40px;
  --avatar_lg_size: 48px;
  --avatar_xl_size: 64px;
  --avatar_xxl_size: 120px;
}

.avatar {
  position: relative;
  object-fit: cover;
  text-align: center;
  border-radius: 50%;
  width: var(--avatar_size);
  height: var(--avatar_size);
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-800);
  font-weight: 500;
  font-size: var(--fs3);
  line-height: 1;
  text-transform: capitalize;
}

.avatar[src$=".svg"] {
  object-fit: contain;
  padding: 7px;
}

.xs {
  width: var(--avatar_xs_size);
  height: var(--avatar_xs_size);
  font-size: var(--fs2);
}

.sm {
  width: var(--avatar_sm_size);
  height: var(--avatar_sm_size);
}

.lg {
  width: var(--avatar_lg_size);
  height: var(--avatar_lg_size);
  font-size: var(--fs6);
}

.xl {
  width: var(--avatar_xl_size);
  height: var(--avatar_xl_size);
  font-size: var(--fs6);
}

.xxl {
  width: var(--avatar_xxl_size);
  height: var(--avatar_xxl_size);
  font-size: var(--fs9);
}

.fallback {
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
}

.fallbackOutline {
  background-color: white;
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}

.rectangular {
  border-radius: var(--br3);
}

.avatarGroup {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
}

.avatarGroup > * {
  margin-left: -12px;
}

.avatarGroup > .avatar:first-of-type {
  margin: 0;
}

.avatarGroupEmpty {
  justify-content: center;
  align-items: center;
  padding: var(--sp1) var(--sp3);
  background-color: white;
  border-radius: var(--br4);
  border: 1px solid var(--color-neutral-100);
  text-align: center;
}

.avatarGroupEmptyText {
  color: var(--color-neutral-500);
}
