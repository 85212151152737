:root {
  --box-shadow-0: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  /* stub comment so lines up with dark.css */
  --box-shadow-1: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  /* stub comment so lines up with dark.css */
  --box-shadow-2: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  /* stub comment so lines up with dark.css */
  --box-shadow-3: 0 15px 25px rgba(0, 0, 0, 0.15),
    0 5px 10px rgba(0, 0, 0, 0.05);
  --box-shadow-4: 0 20px 40px rgba(0, 0, 0, 0.2);

  --body-background-color: var(--color-neutral-000);

  --text-color-primary: var(--color-neutral-800);

  --header-background-color: var(--color-neutral-000);
  --header-color: var(--color-neutral-800);
  --header-border-color: var(--color-neutral-100);

  --sidebar-background-color: var(--color-neutral-000);
  --sidebar-color: var(--color-neutral-900);
  --sidebar-border-color: var(--color-neutral-200);
  --sidebar-border: 1px solid var(--sidebar-border-color);

  --card-background-color: white;
  --card-border: none;

  --heading-1-color: var(--color-neutral-900);
  --heading-2-color: var(--color-primary);
  --heading-3-color: var(--color-neutral-600);

  --label-color-text: var(--color-neutral-900);

  --form-control-filled-background-color: var(--color-neutral-000);
  --form-control-outline-background-color: white;
  --form-control-color: var(--color-neutral-900);
  --form-control-color-placeholder: var(--color-neutral-400);
  --form-control-border-color: var(--color-neutral-200);
  --form-control-focused-border-color: var(--color-primary-l);
  --form-control-focused-box-shadow: var(--color-primary-ll) 0 0 0 4px;
  --form-control-errored-border-color: var(--color-danger-500);
  --form-control-errored-box-shadow: var(--color-danger-100) 0 0 0 4px;
}
