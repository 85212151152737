.navList {
  display: grid;
  grid-auto-flow: row;
  justify-content: stretch;
  align-items: start;
  position: relative;
  /* Match link vertical margin */
  margin: calc(-1 * var(--sp3)) 0;
}

.link {
  display: block;
  line-height: 1;
  color: var(--color-neutral-400);
  background: none;
  border: none;
  font-size: var(--fs2);
  padding: var(--sp1) var(--sp7) var(--sp1) var(--sp4);
  margin: var(--sp3) 0;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  border-left: 5px solid transparent;
}

.link:hover,
.link:active {
  color: var(--color-neutral-500);
}

.linkActive,
.linkActive:hover,
.linkActive:active {
  color: var(--color-neutral-900);
  font-weight: 700;
  border-color: var(--color-primary-l);
}

.linkDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.navTabLayout {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: start;
  gap: var(--sp2);
}

.link svg {
  color: var(--color-neutral-300);
}

.linkActive svg,
.linkActive:hover svg,
.linkActive:active svg {
  color: var(--color-neutral-900);
}

@media (min-width: 768px) {
  .navList {
    min-width: 280px;
    /* border-inline-end: 1px solid var(--color-neutral-100); */
  }
}
