.buttonTransparentPrimary {
  background: transparent;
  color: var(--color-primary);
  border-radius: var(--button-border-radius);
  box-shadow: none;
  text-transform: var(--button-transparent-primary-text-transform);
}

.buttonTransparentPrimary:focus {
  box-shadow: var(--form-control-focused-box-shadow);
}

.buttonTransparentPrimary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonTransparentPrimary:not(:disabled):hover {
    background-color: var(--color-primary-lll);
  }

  .buttonTransparentPrimary:not(:disabled):active {
    opacity: 0.8;
  }
}
