.buttonActivityIndicatorRoot {
  height: 30px;
  width: 30px;
}

.buttonActivityIndicatorSvg {
  animation-name: button_activity_indicator_animation_circular_progress;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.buttonActivityIndicatorSvg > circle {
  stroke: currentColor;
}

@keyframes button_activity_indicator_animation_circular_progress {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
