.menuItemLayout {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: var(--sp2);
}

.menuItemLayout svg {
  color: var(--color-neutral-400);
}

.section {
  padding: var(--sp2) 0;
}

.switchSection {
  padding: var(--sp2) 0;
  border-block-end: 1px solid var(--color-neutral-100);
}

.userSection {
  padding: var(--sp2) var(--sp4) var(--sp3);
  border-block-end: 1px solid var(--color-neutral-100);
}

.userName {
  color: var(--color-neutral-900);
  font-size: var(--fs3);
  line-height: 1.2;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signOutSection {
  padding-block-start: var(--sp2);
  /* border-block-start: 1px solid var(--color-neutral-100); */
}

.settingIcon {
  font: inherit;
  color: var(--color-neutral-400);
  /* Align manually */
  margin-block-start: 3px;
}

.selectedIcon {
  font: inherit;
  font-size: var(--fs5);
  color: var(--color-neutral-800);
  margin-inline-end: var(--sp1);
}

.settingButton {
  padding: var(--sp0) var(--sp4);
}

.itemWithIcon {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: start;
  align-items: center;
  gap: var(--sp2);
}

.orgList {
  max-height: 300px;
  overflow-y: auto;
}

.orgList > *:not(:last-of-type) {
  border-block-end: 1px solid var(--color-neutral-100);
}

.orgSection {
  border-block-end: 1px solid var(--color-neutral-100);
}

.orgSectionContent {
  padding: var(--sp3) var(--sp4);
  color: var(--color-neutral-500);
}

.orgSectionLayout {
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-content: start;
  align-items: center;
  gap: var(--sp3);
}

.orgName {
  font-weight: 500;
  color: var(--color-neutral-900);
  font-size: var(--fs3);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.orgRole {
  color: var(--color-neutral-600);
  font-size: var(--fs0);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.orgRole > *:not(:last-of-type)::after {
  content: ", ";
}

.orgRoleSelected {
  font-weight: 700;
  color: var(--color-neutral-700);
}

.selectedIcon {
  font: inherit;
  font-size: var(--fs5);
  color: var(--color-neutral-800);
}

.sectionHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--sp8);
  padding: var(--sp3) var(--sp4) var(--sp2);
  border-block-end: 1px solid var(--color-neutral-100);
}

.sectionHeading {
  font-weight: 500;
  font-size: var(--fs1);
  color: var(--color-neutral-400);
  text-transform: uppercase;
}

.globalAdminItem {
  padding: var(--sp1) 0;
}
