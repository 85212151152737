.main {
}

.limit {
  margin: 0 auto;
  max-width: var(--pg-width-content-limit);
}

.navContainer {
  padding: var(--sp2) var(--sp4);
}

.backLinkContainer {
  margin: var(--sp4);
}

@media (min-width: 768px) {
  .backLinkContainer {
    margin-block-end: 0;
  }
}
