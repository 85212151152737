.layout {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  justify-items: start;
  align-items: center;
  gap: var(--sp1);
  color: var(--color-neutral-500);
  font-size: var(--fs4);
  font-weight: 700;
}

.layout svg {
  margin-block-start: -2px;
}
